// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:72054aa0-3185-4e33-821c-b334bc2660e1",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_33yuBeHax",
    "aws_user_pools_web_client_id": "9ativl28iufdaatt5li7vl9ha",
    "oauth": {},
    "aws_user_files_s3_bucket": "facing-the-world-photos-production",
    "aws_user_files_s3_bucket_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://gbi4dnqdsrezjeiqt3hutilkmq.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
